import * as React from "react"
import { OutboundLink } from "gatsby-plugin-gtag"

const Footer = () => (
  <footer
    className="cover w-100 white-60 bg-transparent mv0 sans-serif center"
    style={{
      background: `#0e121b`,
    }}
  >
    <div className="w-80-ns w-100 flex flex-column flex-row-ns flex-wrap-m items-center center justify-around">
      <span className="f6 hover-white w-20-l w-40-m w-100 tc br2 ph1 pv2">
        &copy; {new Date().getFullYear()} Gabrielle Moncrease
      </span>
      <OutboundLink
        className="link white-60 hover-light-blue f6 w-20-l w-40-m w-100 ma2 tc bh2 ph1 pv2"
        href="https://www.gatsbyjs.org"
      >
        Built with Gatsby
      </OutboundLink>
      <span className="bh1 f6 hover-white ph1 pv2 tc w-100 w-20-l w-40-m">
        Built with love by Jay &amp; S Creations.
      </span>
      <OutboundLink
        className="br1 f6 hover-light-blue link ph1 pv2 tc white-60 w-100 w-20-l w-40-m"
        href="mailto:webmaster@gabriellemoncrease.com"
      >
        Webmaster
      </OutboundLink>
      <OutboundLink
        className="bl1 f6 hover-light-blue link ph1 pv2 tc white-60 w-100 w-20-l w-40-m"
        href="https://facebook.com/gabriel.moncrease"
        title="Facebook"
      >
        <svg
          className="dib h1 w1"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="1.414"
        >
          <path
            d="M15.117 0H.883C.395 0 0 .395 0 .883v14.234c0 .488.395.883.883.883h7.663V9.804H6.46V7.39h2.086V5.607c0-2.066 1.262-3.19 3.106-3.19.883 0 1.642.064 1.863.094v2.16h-1.28c-1 0-1.195.476-1.195 1.176v1.54h2.39l-.31 2.416h-2.08V16h4.077c.488 0 .883-.395.883-.883V.883C16 .395 15.605 0 15.117 0"
            fillRule="nonzero"
          />
        </svg>
        <span className="ml3 pr2">facebook</span>
      </OutboundLink>
    </div>
  </footer>
)

export default Footer
