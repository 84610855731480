/** https://github.com/tylerwolff/useCookie/ */
import { useState } from "react"
import { isEmpty } from "lodash/fp"

interface UseCookieOptions {
  days: number | "never"
  path: string
}

export function setCookie<S>(
  name: string,
  value: S,
  options: Partial<UseCookieOptions>
): void {
  const optionsWithDefaults = {
    days: 7,
    path: "/",
    ...options,
  }
  const { days, path } = optionsWithDefaults
  let expires
  if (!Number.isNaN(days)) {
    expires = new Date(Date.now() + (days as number) * 864e5).toUTCString()
  } else {
    expires = new Date(8640000000000000).toUTCString()
  }
  try {
    document.cookie = `${name}=${encodeURIComponent(
      JSON.stringify(value)
    )}; expires=${expires}; path=${path}`
  } catch (e) {
    return
  }
}

export const getCookie = (name: string) => {
  try {
    if (isEmpty(name) || isEmpty(document.cookie)) return ""
    return JSON.parse(
      document.cookie.split("; ").reduce((r, v) => {
        const parts = v.split("=")
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
      }, ``)
    )
  } catch (e) {
    return ""
  }
}

export default function<S>(
  key: string,
  initialValue: S
): [S, (prevState: S, options: Partial<UseCookieOptions>) => void] {
  const [item, setItem] = useState(() => {
    return getCookie(key) || initialValue
  })

  const updateItem = (value: S, options: Partial<UseCookieOptions>) => {
    if (value instanceof Function) {
      const tmp = value(item)
      setItem(tmp)
    } else {
      setItem(value)
    }
    setCookie(key, item, options)
  }

  return [item, updateItem]
}
