import { useState, SetStateAction, Dispatch } from "react"

function useLocalStorage<S>(
  key: string,
  initialValue: S
): [S, Dispatch<SetStateAction<S>>] {
  const [stored, setStored] = useState(() => {
    try {
      // Get from local storage by key

      const item = window.localStorage.getItem(key)

      // Parse stored json or if none return initialValue

      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue

      console.error(error)

      return initialValue
    }
  })
  const setValue = (value: S) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(stored) : value

      setStored(valueToStore)

      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.error(error)
    }
  }
  return [stored, setValue]
}
export default useLocalStorage
