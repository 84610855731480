import * as React from "react"
import { Link } from "gatsby"

const Header = (props: HeaderProps) => (
  <header className="mv0 sans-serif bg-purple">
    <div className="mv0 mw9 pb0 pt1 pl2">
      <h1 className="m0 f2">
        <a className="white link hover-light-blue" href="/">
          {props.siteTitle}
        </a>
      </h1>
    </div>
    <nav className="flex justify-between flex-row-ns flex-column center sans-serif">
      <ul className="list flex-row mw-25-ns pt0-ns pb3-ns pl-10-ns flex justify-around items-center">
        <li>
          <Link
            className="f6 link dib white mr3 mr4-ns hover-light-blue"
            to="/"
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            className="f6 link dib white mr3 mr4-ns hover-light-blue"
            to="/contact-us/"
          >
            Contact Us
          </Link>
        </li>
        <li>
          <Link
            className="f6 link dib white mr3 mr4-ns hover-light-blue"
            to="/about/"
          >
            About Gabrielle
          </Link>
        </li>
        <li>
          <Link
            className="f6 link dib white mr3 mr4-ns hover-light-blue"
            to="/books/"
          >
            Books
          </Link>
        </li>
      </ul>
    </nav>
  </header>
)

interface HeaderProps {
  siteTitle?: string
}

Header.defaultProps = {
  siteTitle: `Gabrielle Moncrease.com`,
}

export default Header
