import * as React from "react"
import createPersistedState from "use-persisted-state"
import { toast } from "react-toastify"
import useLocalStore from "./hooks/useLocalStore"
import useCookie from "./hooks/useCookie"

const GDPR = (): JSX.Element => {
  const [optOut, setOptOut] = useLocalStore<boolean>("gdpr-opt-out", null)
  const useGtag = createPersistedState("gbelle-gtags")
  const [gtag, setGtag] = useGtag<boolean>(null)
  const [, setCookie] = useCookie<boolean>("gbelle-gtags", null)
  const days = "never"
  if (optOut === null) {
    return (
      <>
        {toast.info(
          <>
            <p>This site uses cookies and trackers</p>
            <p>
              The purpose of these are to improve the site, but you may wish to
              opt out; learn more information about the European Union&apos;s
              GDPR <a href="https://eugdpr.org">here</a>.
            </p>
            <span>
              <button onClick={() => setOptOut(false)}>
                Accept these trackers
              </button>
              |
              <button onClick={() => setOptOut(true)}>
                Reject the trackers (opt-out)
              </button>
            </span>
          </>,
          { autoClose: false }
        )}
      </>
    )
  }
  if (gtag) return null
  setGtag(optOut)
  setCookie(!optOut, { days })
}
export default GDPR
